var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"fill-height":""}},[_c('div',{class:`conBox ${_vm.isStep1 ? `step1` : ``} ${_vm.role === 'A' ? `admin` : ``}`},[_c('div',{staticClass:"srhTop"},[_c('div',{class:'srhBox ' + _vm.srhBoxFocus},[_c('label',{staticClass:"sel"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.APIList.APIReq.searchType),expression:"APIList.APIReq.searchType"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.APIList.APIReq, "searchType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.APIList.APIReq.searchQuery = ''}]}},_vm._l((_vm.filteredSearchItems),function(item,i){return _c('option',{key:i,domProps:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('label',{staticClass:"tex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.APIList.APIReq.searchQuery),expression:"APIList.APIReq.searchQuery"}],attrs:{"type":"text","placeholder":"검색어를 입력해주세요."},domProps:{"value":(_vm.APIList.APIReq.searchQuery)},on:{"focus":function($event){_vm.srhBoxFocus = 'focusOn'},"blur":function($event){_vm.srhBoxFocus = ''},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.APIList.APIReq.pageNo = 1;
              _vm.getPatientsList();},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.APIList.APIReq, "searchQuery", $event.target.value)}}})]),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"srhBtn btn solid",on:{"click":_vm.search}},[_c('i',{staticClass:"micon"},[_vm._v("search")])])]),(_vm.isStep1)?_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"btn solid",attrs:{"id":"gd_regBtn"},on:{"click":function($event){_vm.popupVisible = true}}},[_vm._v(" 신규 환자 등록 ")]):_vm._e()]),_c('div',{staticClass:"divBox"},[_c('div',{staticClass:"leftBox"},[_c('div',{staticClass:"borderWrap"},[_c('div',{ref:"scrollContainer",staticClass:"tblScroll tblFixed",on:{"scroll":_vm.handleScroll}},[_c('table',{staticClass:"tbl",attrs:{"id":"gd_p_list"}},[_c('caption',[_vm._v(" 환자선택 ")]),_c('colgroup',[(_vm.isStep1)?_c('col',{staticStyle:{"width":"50px"}}):_vm._e(),_c('col',{staticStyle:{"width":"20%"}}),_c('col'),_c('col',{staticStyle:{"width":"20%"}}),_c('col',{staticStyle:{"width":"13%"}}),_c('col',{staticStyle:{"width":"20%"}}),(_vm.role === 'A')?_c('col',{staticStyle:{"width":"13%"}}):_vm._e()]),_c('thead',[_c('tr',_vm._l((_vm.filteredPatientsHeader),function(item,i){return _c('th',{directives:[{name:"ripple",rawName:"v-ripple"}],key:i,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.setSortType(`${item.value}`, $event)}}},[_vm._v(" "+_vm._s(item.name)+" "),(
                        !(_vm.isStep1 && i === 0) && item.value != 'doctorsNm'
                      )?_c('span',{staticClass:"icon"}):_vm._e()])}),0)]),(_vm.isGetingItems)?_c('tbody',_vm._l((_vm.APIList.preSize),function(n){return _c('tr',{key:n},_vm._l((_vm.isStep1 ? 7 : _vm.role === 'A' ? 6 : 5),function(na){return _c('td',{key:na,staticStyle:{"padding":"0 1rem"}},[(_vm.isStep1)?_c('ContentLoader',{attrs:{"speed":1,"width":"100","height":18}},[_c('rect',{attrs:{"x":"15","y":"5","rx":"3","ry":"3","width":70,"height":9}})]):_c('ContentLoader',{attrs:{"speed":1,"width":"100","height":12}},[_c('rect',{attrs:{"x":"15","y":"3","rx":"3","ry":"3","width":70,"height":7}})])],1)}),0)}),0):_vm._e(),(!_vm.isGetingItems && _vm.patientsList.length == 0)?_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":_vm.isStep1 ? `7` : `6`}},[_vm._v(" 조회 결과가 없습니다. ")])])]):_c('tbody',_vm._l((_vm.patientsList),function(item,i){return _c('tr',{key:i,class:{ disabled: _vm.isStep1 && item.state === 'REQST' },staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.checkMyPatient(`${item.state}`, `${item.no}`);
                    _vm.patientInfo = item;}}},[(_vm.isStep1)?_c('td',[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selNo),expression:"selNo"}],staticClass:"selRadi",attrs:{"type":"radio","name":"radi","disabled":item.state === 'REQST' ? true : false},domProps:{"value":item.no,"checked":_vm._q(_vm.selNo,item.no)},on:{"change":function($event){_vm.selNo=item.no}}})])]):_vm._e(),_c('td',{class:_vm.isStep1 && item.state === 'REQST' ? 'disabled' : ''},[_vm._v(" "+_vm._s(item.patientSn)+" ")]),_c('td',{class:_vm.isStep1 && item.state === 'REQST' ? 'disabled' : ''},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{class:_vm.isStep1 && item.state === 'REQST' ? 'disabled' : ''},[_vm._v(" "+_vm._s(item.birth)+" ")]),_c('td',{class:_vm.isStep1 && item.state === 'REQST' ? 'disabled' : ''},[_vm._v(" "+_vm._s(item.sexStr)+" ")]),_c('td',{class:_vm.isStep1 && item.state === 'REQST' ? 'disabled' : ''},[_vm._v(" "+_vm._s(item.lastReadingDt)+" ")]),(_vm.role === 'A')?_c('td',[_c('p',{staticClass:"ellip"},[_vm._v(_vm._s(item.doctorsNm))])]):_vm._e()])}),0)])])])])])]),_c('v-dialog',{model:{value:(_vm.popupVisible),callback:function ($$v) {_vm.popupVisible=$$v},expression:"popupVisible"}},[_c('RegistPopup',{attrs:{"onClick":() => {
          _vm.popupVisible = false;
        },"initialValues":_vm.initialValues},on:{"closePopup":_vm.closeRegPop}})],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.openConfirmPopup),callback:function ($$v) {_vm.openConfirmPopup=$$v},expression:"openConfirmPopup"}},[_c('DefaultPopup',{attrs:{"popupText":`해당 환자를 분석하려면 내환자로 등록해야 합니다.<br>내환자로 추가하시겠습니까?`,"secondTxt":_vm.CONST_OK,"firstBtn":true,"onClick":() => {
          _vm.registMyPatient(_vm.selNo);
        }},on:{"closePopup":_vm.closeRegPop}})],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.openWarnPopup),callback:function ($$v) {_vm.openWarnPopup=$$v},expression:"openWarnPopup"}},[_c('DefaultPopup',{attrs:{"popupText":`${_vm.targetName} 님의 최초 AI 분석입니다.<br>분석을 진행하시면 기본정보를 수정할 수 없습니다.<br>분석을 진행 하시겠습니까?`,"secondTxt":_vm.CONST_OK,"firstBtn":true,"onClick":() => {
          _vm.selectPatientProgress();
        }},on:{"closePopup":_vm.closeWarnPopup}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }