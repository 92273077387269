<template>
  <v-layout fill-height>
    <div
      :class="`conBox ${isStep1 ? `step1` : ``} ${role === 'A' ? `admin` : ``}`"
    >
      <!-- 검색&등록버튼 -->
      <div class="srhTop">
        <div :class="'srhBox ' + srhBoxFocus">
          <label class="sel">
            <select
              v-model="APIList.APIReq.searchType"
              @change="APIList.APIReq.searchQuery = ''"
            >
              <option
                v-for="(item, i) in filteredSearchItems"
                :key="i"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </select>
          </label>
          <label class="tex">
            <input
              type="text"
              placeholder="검색어를 입력해주세요."
              v-model="APIList.APIReq.searchQuery"
              @focus="srhBoxFocus = 'focusOn'"
              @blur="srhBoxFocus = ''"
              @keyup.enter="
                APIList.APIReq.pageNo = 1;
                getPatientsList();
              "
            />
          </label>
          <button class="srhBtn btn solid" v-ripple @click="search">
            <i class="micon">search</i>
          </button>
        </div>
        <button
          v-if="isStep1"
          class="btn solid"
          @click="popupVisible = true"
          v-ripple
          id="gd_regBtn"
        >
          신규 환자 등록
        </button>
      </div>
      <div class="divBox">
        <div class="leftBox">
          <div class="borderWrap">
            <div
              class="tblScroll tblFixed"
              ref="scrollContainer"
              @scroll="handleScroll"
            >
              <table class="tbl" id="gd_p_list">
                <caption>
                  환자선택
                </caption>
                <colgroup>
                  <col v-if="isStep1" style="width: 50px" />
                  <col style="width: 20%" />
                  <col />
                  <col style="width: 20%" />
                  <col style="width: 13%" />
                  <col style="width: 20%" />
                  <col v-if="role === 'A'" style="width: 13%" />
                </colgroup>
                <thead>
                  <tr>
                    <th
                      style="cursor: pointer"
                      v-for="(item, i) in filteredPatientsHeader"
                      :key="i"
                      @click="setSortType(`${item.value}`, $event)"
                      v-ripple
                    >
                      {{ item.name }}
                      <span
                        class="icon"
                        v-if="
                          !(isStep1 && i === 0) && item.value != 'doctorsNm'
                        "
                      ></span>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="isGetingItems">
                  <tr v-for="n in APIList.preSize" :key="n">
                    <td
                      v-for="na in isStep1 ? 7 : role === 'A' ? 6 : 5"
                      :key="na"
                      style="padding: 0 1rem"
                    >
                      <ContentLoader
                        v-if="isStep1"
                        :speed="1"
                        width="100"
                        :height="18"
                      >
                        <rect
                          x="15"
                          y="5"
                          rx="3"
                          ry="3"
                          :width="70"
                          :height="9"
                        />
                      </ContentLoader>
                      <ContentLoader v-else :speed="1" width="100" :height="12">
                        <rect
                          x="15"
                          y="3"
                          rx="3"
                          ry="3"
                          :width="70"
                          :height="7"
                        />
                      </ContentLoader>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="!isGetingItems && patientsList.length == 0">
                  <tr>
                    <td :colspan="isStep1 ? `7` : `6`">
                      조회 결과가 없습니다.
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr
                    v-for="(item, i) in patientsList"
                    :key="i"
                    @click="
                      checkMyPatient(`${item.state}`, `${item.no}`);
                      patientInfo = item;
                    "
                    style="cursor: pointer"
                    :class="{ disabled: isStep1 && item.state === 'REQST' }"
                  >
                    <td v-if="isStep1">
                      <label
                        ><input
                          type="radio"
                          name="radi"
                          class="selRadi"
                          v-model="selNo"
                          :value="item.no"
                          :disabled="item.state === 'REQST' ? true : false"
                      /></label>
                    </td>
                    <td
                      :class="
                        isStep1 && item.state === 'REQST' ? 'disabled' : ''
                      "
                    >
                      {{ item.patientSn }}
                    </td>
                    <td
                      :class="
                        isStep1 && item.state === 'REQST' ? 'disabled' : ''
                      "
                    >
                      {{ item.name }}
                    </td>
                    <td
                      :class="
                        isStep1 && item.state === 'REQST' ? 'disabled' : ''
                      "
                    >
                      {{ item.birth }}
                    </td>
                    <td
                      :class="
                        isStep1 && item.state === 'REQST' ? 'disabled' : ''
                      "
                    >
                      {{ item.sexStr }}
                    </td>
                    <td
                      :class="
                        isStep1 && item.state === 'REQST' ? 'disabled' : ''
                      "
                    >
                      {{ item.lastReadingDt }}
                    </td>
                    <td v-if="role === 'A'">
                      <p class="ellip">{{ item.doctorsNm }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--// 리스트/상세 -->
    </div>
    <!--// 콘텐츠 -->

    <!-- 팝업 - 신규환자 등록 -->
    <v-dialog v-model="popupVisible">
      <RegistPopup
        :onClick="
          () => {
            popupVisible = false;
          }
        "
        :initialValues="initialValues"
        @closePopup="closeRegPop"
      />
    </v-dialog>
    <!--// 팝업 - 신규환자 등록 -->

    <v-dialog v-model="openConfirmPopup" width="400">
      <DefaultPopup
        :popupText="`해당 환자를 분석하려면 내환자로 등록해야 합니다.<br>내환자로 추가하시겠습니까?`"
        :secondTxt="CONST_OK"
        :firstBtn="true"
        :onClick="
          () => {
            registMyPatient(selNo);
          }
        "
        @closePopup="closeRegPop"
      />
    </v-dialog>

    <v-dialog v-model="openWarnPopup" width="400">
      <DefaultPopup
        :popupText="`${targetName} 님의 최초 AI 분석입니다.<br>분석을 진행하시면 기본정보를 수정할 수 없습니다.<br>분석을 진행 하시겠습니까?`"
        :secondTxt="CONST_OK"
        :firstBtn="true"
        :onClick="
          () => {
            selectPatientProgress();
          }
        "
        @closePopup="closeWarnPopup"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import EventBus from "../plugins/EventBus";
import API_PATIENTS_LIST from "../API/patient/list";
import API_REGIST_MY_PATIENT from "../API/patient/registMyPatient";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    RegistPopup: () => import("../popup/registPopup.vue"),
    ContentLoader,
  },
  props: {
    isStep1: {
      default: false,
    },
    initSelect: {
      default: false,
    },
    role: {
      default: "U",
    },
  },
  data() {
    return {
      selNo: null,
      APIList: {
        APIReq: {
          pageNo: 1,
          pageSize: 300,
          searchType: "name",
          searchQuery: "",
          onlyMine: true,
          isPagingList: false,
        },
        total: 0,
        preSize: 1,
      },
      isGetingItems: false,
      searchItems: [
        { name: "환자명", value: "name" },
        { name: "환자번호", value: "patientSn" },
        { name: "담당의", value: "doctorsNm", isAdmin: true },
      ],
      patientsHeader: [
        { name: "선택", value: "", isStep1Obj: true },
        { name: "환자번호", value: "patientSn" },
        { name: "환자명", value: "name" },
        { name: "생년월일", value: "birth" },
        { name: "성별", value: "sexStr" },
        { name: "최근 진료일", value: "lastReadingDt" },
        { name: "담당의", value: "doctorsNm", isAdmin: true },
      ],
      patientsList: [],
      targetDetail: [
        { name: "체중", value: "weight", unit: "kg" },
        { name: "키", value: "height", unit: "cm" },
        { name: "부 체중", value: "fatherWeight", unit: "kg" },
        { name: "부 키", value: "fatherHeight", unit: "cm" },
        { name: "모 체중", value: "motherWeight", unit: "kg" },
        { name: "모 키", value: "motherHeight", unit: "cm" },
        { name: "기타사항", value: "otherDetails" },
      ],
      targetInfo: {},
      radioItem: [
        { name: "내 환자만 조회", value: true },
        { name: "전체 환자 조회", value: false },
      ],
      popupVisible: false,
      isButtonOn: false,
      isListOn: false,
      initialValues: {
        name: "",
        patientSn: "",
        birth: "",
        sex: 0,
        weight: "",
        height: "",
        fatherWeight: "",
        fatherHeight: "",
        motherWeight: "",
        motherHeight: "",
        otherDetails: "",
        crtId: "",
      },
      openConfirmPopup: false,
      patientInfo: {},
      sortBy: [],
      loading: false,
      reachedEnd: false,
      srhBoxFocus: "",
      openWarnPopup: false,
      targetName: "",
    };
  },
  watch: {
    selNo(newValue) {
      if (this.isStep1 && newValue) {
        localStorage.setItem(
          "selectedTargetInfo",
          JSON.stringify(this.patientInfo)
        );
      }
    },
  },
  created() {
    EventBus.on("selectPatient", this.selectPatient);
    EventBus.on("resetSelection", this.clearSelection); // 추가된 이벤트 리스너
  },
  mounted() {
    // 선택된 환자 정보 불러오기
    let selectedPatient = localStorage.getItem("selectedPatientInfo");
    if (selectedPatient) {
      const parsedPatient = JSON.parse(selectedPatient);
      this.selNo = parsedPatient.no;
      this.patientInfo = parsedPatient;
    }

    // Vuex 스토어에서 검색 조건 불러오기
    const storedSearchType = this.$store.getters["page/searchType"];
    const storedSearchQuery = this.$store.getters["page/searchQuery"];
    const storedOnlyMine = this.$store.getters["page/onlyMine"];

    // 조건문 수정: falsy 값 (undefined, null, 빈 문자열)일 경우 기본값 'name' 설정
    this.APIList.APIReq.searchType = storedSearchType || "name"; // 기본값 'name'
    this.APIList.APIReq.searchQuery = storedSearchQuery || "";
    this.APIList.APIReq.onlyMine =
      storedOnlyMine !== undefined ? storedOnlyMine : true;

    //console.log("Mounted - Search Type:", this.APIList.APIReq.searchType);
    //console.log("Mounted - Search Query:", this.APIList.APIReq.searchQuery);
    //console.log("Mounted - Only Mine:", this.APIList.APIReq.onlyMine);

    EventBus.emit("newReading", false);

    this.getPatientsList();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    EventBus.off("selectPatient", this.selectPatient);
    EventBus.off("resetSelection", this.clearSelection); // 이벤트 리스너 해제
  },
  methods: {
    clearSelection() {
      this.selNo = null; // 선택된 환자 초기화
      this.patientInfo = {};
      localStorage.removeItem("selectedPatientInfo");
      //console.log("Selection has been reset in PatientListCompo");
    },
    search() {
      if (!this.APIList.APIReq.searchType) {
        this.showPopup("검색조건을 선택해주세요.");
        return false;
      }
      this.APIList.APIReq.pageNo = 1;
      this.scrollToTop();
      this.getPatientsList();
    },
    async getPatientsList() {
      if (this.isGetingItems) {
        return;
      }
      if (this.role === "A") this.APIList.APIReq.onlyMine = false;
      else this.APIList.APIReq.onlyMine = true;

      this.patientsList = [];
      this.isGetingItems = true;
      let res = await API_PATIENTS_LIST.request(
        this.APIList.APIReq.pageNo,
        this.APIList.APIReq.pageSize,
        this.APIList.APIReq.searchType,
        this.APIList.APIReq.searchQuery,
        this.APIList.APIReq.onlyMine,
        this.sortBy
      );

      /**console.log("getPatientsList - Request Params:", {
        pageNo: this.APIList.APIReq.pageNo,
        pageSize: this.APIList.APIReq.pageSize,
        searchType: this.APIList.APIReq.searchType,
        searchQuery: this.APIList.APIReq.searchQuery,
        onlyMine: this.APIList.APIReq.onlyMine,
        sortBy: this.sortBy,
      });**/

      if (res.isSuccess) {
        this.patientsList = res.list;
        this.APIList.total = res.total;
        this.APIList.preSize = Math.max(1, this.patientsList.length);
        this.APIList.preSize = Math.min(13, this.patientsList.length);
      } else {
        this.showPopup(res.errorMsg, res.status);
      }

      // 검색 조건을 Vuex 스토어에 저장
      this.$store.dispatch(
        "page/setSearchType",
        this.APIList.APIReq.searchType
      );
      this.$store.dispatch(
        "page/setSearchQuery",
        this.APIList.APIReq.searchQuery
      );
      this.$store.dispatch("page/setOnlyMine", this.APIList.APIReq.onlyMine);

      //console.log("getPatientsList - Response:", res);

      this.isGetingItems = false;
    },
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollHeight = scrollContainer.scrollHeight;
      const scrollTop = scrollContainer.scrollTop;
      const clientHeight = scrollContainer.clientHeight;
      const bottomOffset = 900;
      if (scrollHeight - scrollTop - clientHeight <= bottomOffset) {
        this.loadMoreList();
      }
    },
    async loadMoreList() {
      if (this.loading || this.reachedEnd) return;
      if (this.patientsList.length < this.APIList.APIReq.pageSize) return;
      this.loading = true;
      const nextPage = this.APIList.APIReq.pageNo + 1;
      let res = await API_PATIENTS_LIST.request(
        nextPage,
        this.APIList.APIReq.pageSize,
        this.APIList.APIReq.searchType,
        this.APIList.APIReq.searchQuery,
        this.APIList.APIReq.onlyMine,
        this.sortBy
      );

      if (res.isSuccess) {
        this.patientsList = [...this.patientsList, ...res.list];
        this.APIList.APIReq.pageNo = nextPage;
        if (res.list.length < this.APIList.APIReq.pageSize) {
          this.reachedEnd = true;
        }
      } else {
        this.showPopup(res.errorMsg, res.status);
      }
      this.loading = false;
    },
    scrollToTop() {
      this.$refs.scrollContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.reachedEnd = false;
    },
    selectPatient() {
      if (this.selNo == null) {
        this.showPopup("환자를 선택해주세요.");
        return;
      } else {
        if (this.targetInfo && !this.targetInfo.lastReadingDt) {
          this.targetName = this.targetInfo.name;
          this.openWarnPopup = true;
        } else {
          this.selectPatientProgress();
        }
      }
    },
    selectPatientProgress() {
      if (this.openWarnPopup) this.openWarnPopup = false;
      localStorage.setItem("targetInfo", JSON.stringify(this.targetInfo));
      EventBus.emit("change_target");
      this.$router.push({ name: "step2" });
    },
    closeWarnPopup() {
      this.openWarnPopup = false;
      this.targetInfo = {};
      localStorage.setItem("targetInfo", JSON.stringify(this.targetInfo));
      EventBus.emit("change_target");
      this.selNo = null;
    },
    toggleList() {
      this.isButtonOn = !this.isButtonOn;
      this.isListOn = !this.isListOn;
    },
    async registMyPatient(no) {
      let res = await API_REGIST_MY_PATIENT.request(no);
      if (res.isSuccess) {
        this.getPatientsList();
        this.targetInfo = this.patientInfo;
        this.openConfirmPopup = false;
      } else {
        this.showPopup(res.errorMsg, res.status);
      }
    },
    setSortType(item, event) {
      if (item === "doctorsNm") return;
      const classNames = ["up", "down"];
      let order = "";
      let targetElement = event.target;
      if (targetElement.className.includes("icon"))
        targetElement = targetElement.closest("th");
      const isUpClass = classNames.includes(targetElement.className);
      if (isUpClass) {
        order = targetElement.className === "up" ? "desc" : "init";
        targetElement.className =
          targetElement.className === "up" ? "down" : "default";
      } else {
        order = "asc";
        targetElement.className = "up";
      }

      const existingIndex = this.sortBy.findIndex((obj) => obj.key === item);
      if (existingIndex !== -1) {
        if (order === "init") {
          this.sortBy.splice(existingIndex, 1);
        } else {
          this.sortBy[existingIndex].order = order;
        }
      } else {
        this.sortBy.push({ key: item, order: order });
      }
      this.APIList.APIReq.pageNo = 1;
      this.scrollToTop();
      this.getPatientsList();
    },
    showPatient(no, isDetail, chkMine) {
      this.$router.push({
        name: "patient",
        params: { no: no, isDetail: isDetail, chkMine: chkMine },
      });
    },
    showPatientInfo(no, isDetail) {
      this.$router.push({
        name: "patientInfo",
        params: { no: no, isDetail: isDetail },
      });
    },
    checkMyPatient(state, no) {
      if (this.isStep1) {
        if (state === "REQST") {
          this.selNo = null;
          this.resetSelection();
          return;
        } else {
          this.selNo = no;
          // 선택된 환자 정보를 저장
          const selectedPatient = this.patientsList.find(
            (patient) => patient.no === no
          );
          if (selectedPatient) {
            this.patientInfo = selectedPatient;
            localStorage.setItem(
              "selectedPatientInfo",
              JSON.stringify(selectedPatient)
            );
          }
        }
      } else {
        if (this.role === "A") return;
        else this.showPatient(no, true, true); // 사용자 상세 페이지로 이동
      }
    },
    chgOnlyMine() {
      this.APIList.APIReq.pageNo = 1;
      this.getPatientsList();
      this.scrollToTop();
    },
    closeRegPop(progress) {
      if (progress) this.getPatientsList();
      this.openConfirmPopup = false;
      this.popupVisible = false;
    },
    resetSelection() {
      this.selNo = null;
      localStorage.removeItem("selectedPatientInfo");
      //console.log("Selection has been reset in PatientListCompo");
    },
  },
  computed: {
    filteredPatientsHeader() {
      if (this.isStep1) {
        return this.patientsHeader.filter((item) => item.isAdmin !== true);
      } else {
        if (this.role === "A") {
          return this.patientsHeader.filter((item) => item.isStep1Obj !== true);
        } else {
          return this.patientsHeader.filter(
            (item) => item.isStep1Obj !== true && item.isAdmin !== true
          );
        }
      }
    },
    filteredSearchItems() {
      if (this.role === "A") {
        return this.searchItems;
      } else {
        return this.searchItems.filter((item) => item.isAdmin !== true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.tblScroll {
  max-height: 655px;
  .tbl {
    border-bottom: 0;
  }
}

::v-deep .v-input.v-input--selection-controls {
  padding-top: 0 !important;
}

button {
  transition: box-shadow 0.5s, opacity 0.5s;
}
button:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.7;
}

.focusOn {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

::v-deep .primary--text {
  color: var(--color-point) !important;
  caret-color: var(--color-point) !important;
}

.srhTop .srhBox {
  width: 100%;
}
.conBox .divBox {
  margin-top: 40px;
}

.conBox.step1 {
  padding: 24px;
  height: auto;
}
.conBox.step1 .srhTop .srhBox {
  width: calc(70% - 20px);
}
.conBox.step1 .divBox {
  margin-top: 24px;
}
.conBox.step1 .tblScroll {
  max-height: 475px;
}
.conBox.step1 .borderWrap {
  border: 1px solid #dbdbdb;
}

.conBox.admin .tbl tbody tr:hover,
.conBox.admin .tbl tbody td:hover {
  cursor: default !important;
}
td.disabled {
  color: rgba(0, 0, 0, 0.3);
}
</style>
